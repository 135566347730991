<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      wristbandconfig: {
        maxprice: {
          active: false,
          device: [],
        },
      },
      wristbandextend: {},
      userinfo: [],
      devicelist: [],
      wristbandgetloading: true,
      wristbandloading: false,
      wristbandgetextloading: true,
      wristband_ext_loading: false,
      actionlimit: {
        status: false,
        time: "08:00:00",
      },
      limitloading: false,
      timelimit: {
        status: false,
        time: 20,
        value: 250,
      },
      settingtag: "wristband",
      form: {
        systemtype: 0,
      },
    };
  },
  mounted() {
    this.title = this.$t("menuitems.marketing.wristband.text");
    this.items = [
      {
        text: this.$t("menuitems.marketing.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.marketing.wristband.text"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('menuitems.devices.lists')
    this.getdevicelist();
    this.getwristbandsetting();
    this.getwristbandextsetting();
    this.getwristbandlimit();
    this.gettimelimit();
  },
  methods: {
    getwristbandsetting() {
      var that = this;
      that.wristbandgetloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getwristbandsetting",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.wristbandgetloading = false;
          that.wristbandconfig = response.data.config;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getwristbandextsetting() {
      var that = this;
      that.wristbandgetextloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getwristband_ext_setting",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.wristbandgetextloading = false;
          that.wristbandextend = response.data.config;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getwristbandlimit() {
      var that = this;
      that.limitloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getwristband_limit",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.limitloading = false;
          if (response.data.status == 200) {
            that.actionlimit = response.data.config;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    gettimelimit() {
      var that = this;
      that.limitloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getwristband_timelimit",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.limitloading = false;
          if (response.data.status == 200) {
            that.timelimit = response.data.config;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getdevicelist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getdevicelist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.devicelist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    save() {
      var that = this;
      that.wristbandloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savewristbandsetting",
            config: this.wristbandconfig.maxprice,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.wristbandloading = false;
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    save_extend() {
      var that = this;
      that.wristband_ext_loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savewristband_ext_setting",
            config: this.wristbandextend,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.wristband_ext_loading = false;
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    save_limit() {
      var that = this;
      that.limitloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savewristband_limit",
            config: this.actionlimit,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.limitloading = false;
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    save_timelimit() {
      var that = this;
      that.limitloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savewristband_timelimit",
            config: this.timelimit,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.limitloading = false;
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <el-tabs v-model="settingtag">
                <el-tab-pane :label="$t('marketing.wristband.drink')" name="wristband">
                  <el-form ref="form" :model="wristbandconfig.maxprice" label-width="100px">
                    <el-form-item :label="$t('marketing.wristband.active')">
                      <el-switch v-model="wristbandconfig.maxprice.active"></el-switch>
                    </el-form-item>
                    <el-form-item :label="$t('marketing.wristband.systemmode')">
                      <el-select v-model="wristbandconfig.maxprice.systemtype"
                        :placeholder="$t('marketing.wristband.systemmode')">
                        <el-option :label="$t('marketing.wristband.standard')" value="0"></el-option>
                        <el-option :label="$t('marketing.wristband.simple')" value="1"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('marketing.wristband.mode')" v-if="wristbandconfig.maxprice.systemtype == 0">
                      <el-switch v-model="wristbandconfig.maxprice.usermode"
                        :active-text="$t('marketing.wristband.usermode')"
                        :inactive-text="$t('marketing.wristband.globalmode')"></el-switch>
                    </el-form-item>

                    <el-form-item :label="$t('marketing.wristband.activedevice')" v-if="wristbandconfig.maxprice.systemtype == 0">
                      <el-select v-model="wristbandconfig.maxprice.device" filterable multiple :placeholder="$t('marketing.wristband.selectactivedevice')
      " value-key="ED_id">
                        <el-option v-for="val in devicelist" :label="val.name" :value="val.ED_id"
                          :key="val.ED_id"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('marketing.wristband.price')" v-if="wristbandconfig.maxprice.systemtype == 0">
                      <el-input type="tel" v-model="wristbandconfig.maxprice.price"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('marketing.wristband.min')" v-if="wristbandconfig.maxprice.systemtype == 0">
                      <el-switch v-model="wristbandconfig.maxprice.min"></el-switch>
                    </el-form-item>
                    <el-form-item :label="$t('marketing.wristband.time')" v-if="!wristbandconfig.maxprice.usermode">
                      <el-time-picker is-range v-model="wristbandconfig.maxprice.time"
                        :range-separator="$t('marketing.wristband.timeto')" :start-placeholder="$t('marketing.wristband.starttime')
      " :end-placeholder="$t('marketing.wristband.enttime')" :placeholder="$t('marketing.wristband.select')"
                        format="HH:mm:ss">
                      </el-time-picker>
                    </el-form-item>
                    <el-form-item :label="$t('marketing.wristband.timeoverdisable')">
                      <el-switch v-model="wristbandconfig.maxprice.overtime"></el-switch></el-form-item>

                    <el-form-item>
                      <el-button type="primary" @click="save" v-loading="wristbandloading">{{ $t("global.button.save")
                        }}</el-button>
                    </el-form-item>
                  </el-form>

                </el-tab-pane>
                <el-tab-pane :label="$t('marketing.wristband.extend')" name="extend">
                  <el-form ref="form" :model="wristbandextend" label-width="100px">
                    <el-form-item :label="$t('marketing.wristband.saveanonymous')">
                      <el-switch v-model="wristbandextend.saveanonymous"></el-switch>
                    </el-form-item>

                    <el-form-item :label="$t('marketing.wristband.autoaddgroupo')">
                      <el-switch v-model="wristbandextend.autoaddgroupo"></el-switch>
                      <el-alert :title="$t('marketing.wristband.autoaddgroupotip')" type="warning" :closable="false">
                      </el-alert>
                    </el-form-item>

                    <el-form-item>
                      <el-button type="primary" @click="save_extend" v-loading="wristbandloading">{{
      $t("global.button.save") }}</el-button>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
                <el-tab-pane :label="$t('marketing.wristband.times')" name="timelimit">
                  <el-form ref="form" :model="timelimit" label-width="100px">
                    <el-form-item :label="$t('marketing.wristband.active')">
                      <el-switch v-model="timelimit.status" v-if="actionlimit.status == false"></el-switch>
                      <el-switch v-model="timelimit.status" v-else disabled></el-switch>
                    </el-form-item>
                    <el-form-item :label="$t('marketing.wristband.limittime')">
                      <el-input-number v-model="timelimit.time" :min="1" :max="60" :step="1"></el-input-number>
                      分钟
                    </el-form-item>
                    <el-form-item :label="$t('marketing.wristband.limitvalue')">
                      <el-input-number v-model="timelimit.value" :min="1" :max="6000" :step="50"></el-input-number>
                      ML
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="save_timelimit">{{
      $t("global.button.save") }}</el-button>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
                <el-tab-pane :label="$t('marketing.wristband.limit')" name="beerlimttype2">
                  <el-form ref="form" :model="actionlimit" label-width="100px">
                    <el-form-item :label="$t('marketing.wristband.active')">
                      <el-switch v-model="actionlimit.status" v-if="timelimit.status == false"></el-switch>
                      <el-switch v-model="actionlimit.status" v-else disabled></el-switch>
                    </el-form-item>
                    <el-form-item :label="$t('marketing.wristband.resettime')">
                      <el-time-picker v-model="actionlimit.time" :picker-options="{
      selectableRange: '00:00:00 - 23:59:59'
    }" value-format="HH:mm:ss" placeholder="选择时间">
                      </el-time-picker>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="save_limit">{{
                        $t("global.button.save") }}</el-button>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>